"use client"; // Error components must be Client Components

import { useEffect } from "react";

export default function Error({ error, reset}) {
  useEffect(() => {
    // Log the error to an error reporting service
    console.error(error);
  }, [error]);
 
  return (
    <div style={{ padding: "30vh 0px", textAlign: "center" }}>
      <h2>টেকনিক্যাল সমস্যার জন্য আন্তরিকভাবে দুঃখিত!</h2>
      <button onClick={() => reset()} className="btn btn-primary p-2">
        অনুগ্রহপূর্বক আবার চেষ্টা করুন
      </button>
    </div>
  );
}
